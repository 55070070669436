import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-839e2c98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-list" }
const _hoisted_2 = { class: "account-list__icon" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "flex-grow-1 lh-20" }
const _hoisted_5 = { class: "subhead-semi-bold-16 lh-24 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.title,
        class: "account-list__position"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: item.icon,
            alt: item.title
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
          _createVNode(_component_tm_stylized_text, {
            class: "neutral--text",
            text: item.description
          }, null, 8, ["text"])
        ])
      ]))
    }), 128))
  ]))
}