
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStepList from '@/components/shared/templates/FlowStepList.vue'
import ConnectAccountStep from '@/components/pages/chats/settings/facebookMessenger/flow/ConnectAccountStep.vue'
import NumberStep from '@/components/pages/chats/settings/facebookMessenger/flow/NumberStep.vue'

export default defineComponent({
  components: {
    TmButton,
    DetailsHero,
    PageBlock,
    FlowStepList,
    ConnectAccountStep,
    NumberStep,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Facebook Messenger', url: { name: 'base.chats.settings.facebookMessenger' } },
      { label: 'Connect Facebook page' },
    ]
    const currentStep = ref(1)

    return {
      breadcrumbs,
      currentStep,
    }
  },
})
