import type { AccountListType } from '@/definitions/chats/settings/common/types'
import type { FacebookLineType } from '@/definitions/chats/settings/facebookMessenger/types'

export const facebookMock: FacebookLineType[] = [
  {
    id: 1,
    page: {
      title: 'Acme LLC (Australia)',
      avatarColor: 'purple',
      link: 'https://www.facebook.com/',
    },
    account: 'Darlene Robertson',
    chatLink: 'https://m.me/23404354543543',
    status: {
      name: 'Connected',
      color: 'green',
      outline: true,
    },
    dateConnected: '2024-05-07T17:58:27.118Z',
  },
  {
    id: 2,
    page: {
      title: 'Personal Storytelling Market',
      image: 'https://picsum.photos/id/222/200',
      avatarColor: 'red',
      link: 'https://www.facebook.com/',
    },
    account: 'Darlene Robertson',
    chatLink: 'https://m.me/23404354543543',
    status: {
      name: 'Connected',
      color: 'green',
      outline: true,
    },
    dateConnected: '2024-05-11T17:02:27.118Z',
  },
  {
    id: 3,
    page: {
      title: 'Welcome to Australia',
      avatarColor: 'blue',
      link: 'https://www.facebook.com/',
    },
    account: 'Floyd Miles',
    chatLink: 'https://m.me/2340435458989',
    status: {
      name: 'Connection failed',
      color: 'red',
      outline: true,
    },
    dateConnected: '2024-06-08T11:11:27.118Z',
  },
  {
    id: 4,
    page: {
      title: 'Australia sim-racing',
      image: 'https://picsum.photos/id/99/200',
      avatarColor: 'mint',
      link: 'https://www.facebook.com/',
    },
    account: 'Floyd Miles',
    chatLink: 'https://m.me/2340435458989',
    status: {
      name: 'Permissions issue',
      color: 'red',
      outline: true,
    },
    dateConnected: '2024-04-01T15:32:27.118Z',
  },
]

export const facebookAccountList: AccountListType[] = [
  {
    icon: require('@/assets/images/byoc/meta.svg'),
    title: 'Facebook by Meta',
    description: 'Click the Login with Facebook button to log in to your Facebook Business Account.',
  },
  {
    icon: require('@/assets/images/chats/meta.svg'),
    title: 'Facebook Messenger',
    description: 'Follow the guided setup to connect your Facebook pages. Ensure you have the necessary permissions in your Facebook account to complete the process.',
  },
]
