
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import PagesList from '@/components/pages/chats/settings/facebookMessenger/flow/PagesList.vue'
import { facebookMock } from '@/definitions/chats/settings/facebookMessenger/data'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    TmButton,
    FlowStep,
    PagesList,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['step-change'],
  setup() {
    const router = useRouter()
    const finishClickNumber = ref(1)
    const pagesList = ref(facebookMock.slice(0, 1))
    const finishClick = () => {
      if (finishClickNumber.value === 1) {
        finishClickNumber.value++
        pagesList.value = facebookMock.slice(0, 3)
      } else {
        router.push({ name: 'base.chats.settings.facebookMessenger' })
      }
    }
    return {
      pagesList,
      finishClick,
    }
  },
})
