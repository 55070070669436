import { useModals } from '@/compositions/modals'

type UseChatsSettings = {
  openNewWidgetModal: () => void;
  openEditWidgetModal: () => void;
  openDeactivateWidgetModal: () => void;
  openDeactivateWidgetsModal: (selected: any[]) => void;
  openActivateWidgetModal: () => void;
  openActivateWidgetsModal: (selected: any[]) => void;
  openDeleteWidgetModal: () => void;
  openTestWidgetPage: () => void;
  openDeleteWidgetsModal: (selected: any[]) => void;
  openDuplicateWidgetModal: () => void;
  openWhatsAppQrCodeModal: (number: string, title: string) => void;
  openFacebookQrCodeModal: (title: string, chatLink: string) => void;
  openFacebookUnlinkModal: (title: string) => void;
  openInstagramQrCodeModal: (title: string, chatLink: string) => void;
  openInstagramUnlinkModal: (title: string) => void;
}

export const useChatsSettings = (): UseChatsSettings => {
  const { openModal } = useModals()

  const openNewWidgetModal = () => {
    openModal('webWidgets')
  }

  const openEditWidgetModal = () => {
    openModal('webWidgets', {
      modalTitle: 'Edit widget',
      btnTitle: 'Save',
      name: 'InteractBeam',
      domain: 'interactbeam.com',
    })
  }

  const openDeactivateWidgetModal = () => {
    openModal('confirmation', {
      title: 'Deactivate widget',
      btnColor: 'error',
      btnText: 'Deactivate',
      text: [
        'Deactivating this web widget ',
        {
          text: 'InteractBeam ',
          style: 'semi-bold',
        },
        'will result in hiding this widget from all pages.\n\nAre you sure you would like to continue?',
      ],
    })
  }
  const openDeactivateWidgetsModal = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Deactivate widgets',
      btnColor: 'error',
      btnText: `Deactivate ${selected.length} widgets`,
      text: [
        'Deactivating these ',
        {
          text: `${selected.length} web widgets `,
          style: 'semi-bold',
        },
        'will result in hiding these widgets from all pages.\n\nAre you sure you would like to continue?',
      ],
    })
  }

  const openActivateWidgetModal = () => {
    openModal('confirmation', {
      title: 'Activate widget',
      btnColor: 'primary',
      btnText: 'Activate',
      text: [
        'Activating this web widget ',
        {
          text: 'InteractBeam ',
          style: 'semi-bold',
        },
        'will result in displaying this widget on all pages where the code is installed.\n\nAre you sure you would like to continue?',
      ],
    })
  }

  const openActivateWidgetsModal = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Activate widgets',
      btnColor: 'primary',
      btnText: `Activate ${selected.length} widgets`,
      text: [
        'Activating these ',
        {
          text: `${selected.length} web widgets `,
          style: 'semi-bold',
        },
        'will result in displaying these widgets on all pages where the code is installed.\n\nAre you sure you would like to continue?',
      ],
    })
  }
  const openDeleteWidgetModal = () => {
    openModal('confirmation', {
      title: 'Delete widget',
      btnColor: 'error',
      btnText: 'Delete',
      text: [
        'Deleting this web widget ',
        {
          text: 'InteractBeam ',
          style: 'semi-bold',
        },
        'will result in removing this widget from all pages. This change cannot be undone.\n\nAre you sure you would like to delete this widget?',
      ],
    })
  }
  const openDeleteWidgetsModal = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Delete widgets',
      btnColor: 'error',
      btnText: `Delete ${selected.length} widgets`,
      text: [
        'Deleting these ',
        {
          text: `${selected.length} web widgets `,
          style: 'semi-bold',
        },
        'will result in removing these widgets from all pages. This change cannot be undone.\n\nAre you sure you would like to delete these widgets?',
      ],
    })
  }
  const openDuplicateWidgetModal = () => {
    openModal('duplicate', {
      itemName: 'widget',
      originalName: 'InteractBeam',
    })
  }
  const openWhatsAppQrCodeModal = (number: string, title: string) => {
    openModal('whatsAppQrCode', {
      number: number,
      title: title,
    })
  }

  const openTestWidgetPage = () => {
    window.open('https://layout-s.textmagictesting.com/widget-test-page-chat', '_blank')
  }

  const openFacebookQrCodeModal = (title: string, chatLink: string) => {
    openModal('socialQrCode', {
      title: title,
      chatLink: chatLink,
      text: [
        'Share this QR code or link to direct people to a Messenger conversation with the ',
        {
          text: title,
          style: 'semi-bold',
        },
        ' page.',
      ],
      image: require('@/assets/images/byoc/qr-code-facebook.png'),
    })
  }

  const openFacebookUnlinkModal = (title: string) => {
    openModal('confirmation', {
      title: 'Unlink Facebook page',
      btnColor: 'error',
      btnText: 'Unlink page',
      text: [
        'Are you sure you want to disconnect the ',
        {
          text: title,
          style: 'semi-bold',
        },
        ' page? Once disconnected, you will not be able to manage Messenger conversations from this page in Textmagic.',
      ],
    })
  }

  const openInstagramQrCodeModal = (title: string, chatLink: string) => {
    openModal('socialQrCode', {
      title: title,
      chatLink: chatLink,
      text: [
        'Share this QR code or link to direct people to a conversation with the ',
        {
          text: title,
          style: 'semi-bold',
        },
        ' account.',
      ],
      image: require('@/assets/images/byoc/qr-code-instagram.png'),
    })
  }

  const openInstagramUnlinkModal = (title: string) => {
    openModal('confirmation', {
      title: 'Unlink Instagram account',
      btnColor: 'error',
      btnText: 'Unlink account',
      text: [
        'Are you sure you want to disconnect the ',
        {
          text: title,
          style: 'semi-bold',
        },
        ' account? Once disconnected, you will no longer be able to manage or respond to conversations from this Instagram account.',
      ],
    })
  }

  return {
    openNewWidgetModal,
    openEditWidgetModal,
    openDeactivateWidgetModal,
    openDeactivateWidgetsModal,
    openActivateWidgetModal,
    openActivateWidgetsModal,
    openDeleteWidgetModal,
    openDeleteWidgetsModal,
    openDuplicateWidgetModal,
    openWhatsAppQrCodeModal,
    openTestWidgetPage,
    openFacebookQrCodeModal,
    openFacebookUnlinkModal,
    openInstagramQrCodeModal,
    openInstagramUnlinkModal,
  }
}
