
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import FlowStep from '@/components/shared/templates/FlowStep.vue'
import AccountList from '@/components/pages/chats/settings/AccountList.vue'
import { facebookAccountList } from '@/definitions/chats/settings/facebookMessenger/data'

export default defineComponent({
  components: {
    TmButton,
    FlowStep,
    AccountList,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ['step-change'],
  setup() {
    return {
      facebookAccountList,
    }
  },
})
